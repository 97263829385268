import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { changeAutoLanguage } from '@/redux/reducers/auth';
import { Box } from '@mui/material';
import { useState, useEffect } from 'react';

export default function CardapioTranslationOptions() {
    const dispatch = useAppDispatch();
    const language = useAppSelector(state => state.auth.language);

    const languages = [
        {
            value: 'PT',
            imgSrc: 'https://d29ap4304jrr71.cloudfront.net/galeria/galeria-66142-1730380862.jpeg',
        },
        {
            value: 'EN',
            imgSrc: 'https://d29ap4304jrr71.cloudfront.net/galeria/galeria-66141-1730380862.jpeg',
        },
        {
            value: 'ES',
            imgSrc: 'https://d29ap4304jrr71.cloudfront.net/galeria/galeria-66140-1730380861.jpeg',
        },
    ];

    const initialIndex = languages.findIndex(lang => lang.value === language);
    const [currentIndex, setCurrentIndex] = useState(initialIndex !== -1 ? initialIndex : 0);

    const handleLanguageChangeFlag = () => {
        const nextIndex = (currentIndex + 1) % languages.length; 
        setCurrentIndex(nextIndex);
        dispatch(changeAutoLanguage(languages[nextIndex].value as 'EN' | 'ES' | 'PT'));
    };

    useEffect(() => {
        const updatedIndex = languages.findIndex(lang => lang.value === language);
        if (updatedIndex !== -1) setCurrentIndex(updatedIndex);
    }, [language]);

    return (
        <Box onClick={handleLanguageChangeFlag}>
            <Box component="img" src={languages[currentIndex].imgSrc} sx={{ height: '1.8em', width: '2.4em', borderRadius: '0.2rem' }} alt="Bandeiras" />
        </Box>
    );
}
