import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Modal } from '@mui/material';
import { GetStatusTransition } from '@/services/delivery/payment';
import ErrorComp from './components/Error';
import ContentComp from './components/Content';
import ModalSuccess from '../ModalSucess';
import { reset } from '@/redux/reducers/delivery/payment';
import DeliveryStorage from '@/storages/delivery-storage';
import { usePages } from '@/contexts/pages';
import { ListOrder } from '@/services/delivery/order';

export default function ModalPix() {
	const { responsePayment, status } = useAppSelector(state => state.paymentDelivery);
	const { customer } = useAppSelector(state => state.userDelivery);
	const dispatch = useAppDispatch();
	const [open, setOpen] = useState(false);
	const [openSuccess, setOpenSuccess] = useState(false);
	const countDownDate = new Date().getTime() + 5 * 60 * 1000;
	const [time, setTime] = useState(' ');
	const [requestInterval, setRequestInterval] = useState<any>(null);
	const [intervalTime, setIntervalTime] = useState<any>(null);
	const { setOpenPayment, setOpenOrder } = usePages();
	const [pixKey, setPixKey] = useState('');
	const [alias, setAlias] = useState('');

	useEffect(() => {
		const url = window.location.href;
		if (url) {
			setAlias(url.split('/')[2].split('.')[0]);
		}
	}, []);

	function clearAll() {
		DeliveryStorage.RemovePix(alias);
		DeliveryStorage.RemoveTimePayment(alias);
		clearInterval(requestInterval);
		clearInterval(intervalTime);
		setTime('5:00');
		setOpen(false);
	}

	function generateTime(downTime?: number) {
		const time = setInterval(function () {
			var now = new Date().getTime();
			var distance = (downTime || countDownDate) - now;

			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
			DeliveryStorage.SetTimePayment(minutes + '.' + seconds, alias);

			if (distance < 0) {
				DeliveryStorage.RemovePix(alias);
				DeliveryStorage.RemoveTimePayment(alias);
				clearInterval(time);
				clearInterval(intervalTime);
				setTime('0:00');
			} else {
				setTime(minutes + ':' + (seconds < 10 ? '0' : '') + seconds);
			}
		}, 1000);
		setIntervalTime(time);
	}

	useEffect(() => {
		if (alias) {
			const storageTimePayment = DeliveryStorage.GetTimePayment(alias);

			if (storageTimePayment) {
				const storagePix = DeliveryStorage.GetPix(alias);
				if (storagePix) {
					setPixKey(storagePix.pixKey);
					setOpen(true);
					generateTime(new Date().getTime() + storageTimePayment * 60 * 1000);
					getStatus(storagePix.transitionId);
				}
			}
		}
	}, [alias]);

	async function getStatus(transitionId: number) {
		if (responsePayment) {
			DeliveryStorage.SetPix(
				{
					pixKey: responsePayment.chave_pix || '',
					transitionId: responsePayment.transacao,
				},
				alias,
			);
		}
		const interval = setInterval(async function () {
			await dispatch(GetStatusTransition(transitionId));
		}, 3000);
		setRequestInterval(interval);
	}

	useEffect(() => {
		if (status) {
			if (status.status !== '1' || time === '0:00') {
				DeliveryStorage.RemovePix(alias);
				DeliveryStorage.RemoveTimePayment(alias);
				return () => {
					clearInterval(requestInterval);
					clearInterval(intervalTime);
				};
			}
		}
	}, [status, time]);

	useEffect(() => {
		if (status) {
			if (status.status === '3') {
				clearAll();
				setOpenSuccess(true);
				setOpen(false);
				return () => {
					clearInterval(requestInterval);
					clearInterval(intervalTime);
				};
			}
		}
	}, [status]);

	useEffect(() => {
		if (responsePayment && responsePayment.chave_pix) {
			setPixKey(responsePayment.chave_pix);
			setOpen(true);
			generateTime();
			getStatus(responsePayment.transacao);
		}
	}, [responsePayment]);

	function onConfirmError() {
		dispatch(reset());
		clearAll();
		setOpenPayment(true);
	}

	function renderContent() {
		if ((status && status.status === '6') || time === '0:00') {
			return <ErrorComp onConfirmError={onConfirmError} />;
		} else {
			return <ContentComp time={time} pixKey={pixKey} />;
		}
	}

	return (
		<>
			<Modal open={open} id="modalPix" disablePortal disableEnforceFocus disableAutoFocus disableScrollLock>
				<div className="pixContent">{renderContent()}</div>
			</Modal>
			<ModalSuccess
				open={openSuccess}
				title="Pagamento efetuado com sucesso!"
				subtitle="Obrigado(a) pela preferência!"
				onClick={() => {
					setOpenSuccess(false);
					if (customer) {
						(async () => {
							await dispatch(ListOrder(customer.conteudo.cpf || customer.conteudo.fone || ''));
							await setOpenOrder(true);
							await dispatch(reset());
						})();
					}
					localStorage.clear();
					sessionStorage.clear();
				}}
			/>
		</>
	);
}
